import React from "react";
import "./Footer.scss";
import {
  FaInstagram,
  FaFacebook,
  FaBandcamp,
  FaYoutubeSquare,
} from "react-icons/fa";
import { SiTiktok } from "react-icons/si";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p> Boy Golden &copy; {currentYear}</p>

      <div className="social-icon-container">
        <div className="icon-row">
          <a
            href="https://www.instagram.com/boygolden/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="instagram link"
            aria-hidden="true"
          >
            <FaInstagram />
          </a>
        </div>

        <div className="icon-row">
          <a
            href="https://www.facebook.com/boygolden"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook link"
            aria-hidden="true"
          >
            <FaFacebook />
          </a>
        </div>

        <div>
          <div>
            <div className="icon-row">
              <a
                href="https://yaboygolden.bandcamp.com/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Bandcamp Link"
                aria-hidden="true"
              >
                <FaBandcamp />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="icon-row">
            <a
              href="https://www.tiktok.com/@ya.boy.golden?"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="TikTok Link"
              aria-hidden="true"
            >
              <SiTiktok />
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              href="https://www.youtube.com/channel/UCFuCZ_8LFQa2wTbt-RIWUBQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutubeSquare />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
